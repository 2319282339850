import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';


@Component({
    selector: 'ap-alert-view',
    templateUrl: './ap-alert-view.component.html',
    styleUrls: ['./ap-alert-view.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class AnnaPerennaAlertViewComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  alarmId: string;
  alert:AnnaPerennaAlert;
  errorMsg: string;
  
  ngOnInit(): void {

  }
}

class AnnaPerennaAlert {
  alertId: string;
  imageData: string;
  timeStamp: Date;
}
