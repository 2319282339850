import { CompanyManagementComponent } from './components/company-management/company-management/company-management.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, LoginGuard, RoleGuard, FeatureGuard, SSOAuthGuard, SSORoleGuard } from './services/guard.service';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardAdminComponent } from './components/dashboard-admin/dashboard-admin.component';
import { ReportComponent } from './components/report/report.component';
import { GraphsComponent } from './components/report/graphs/graphs.component';
import { ActivityComponent } from './components/report/activity/activity.component';
import { DevicesComponent } from './components/devices/devices.component';
import { DeviceDatabaseComponent } from './components/device-database/device-database.component';
import { SystemFirmwareComponent } from './components/system/system-firmware/system-firmware.component';
import { AnnaPerennaAlertViewComponent } from './components/anna-perenna/ap-alert-view/ap-alert-view.component';
import { AnnaPerennaMonitorComponent } from './components/anna-perenna/ap-monitor/ap-monitor.component';
import { StaffComponent } from './components/staff/staff.component';
import { AnnaPerennaMonitorEditComponent } from './components/anna-perenna/ap-monitor/ap-monitor-edit/ap-monitor-edit.component';
import { ControlRoomComponent } from './components/control-room/control-room.component';
import { AlarmRouteComponent } from './components/alarm-route/alarm-route.component';
import { ReceiverTeamsComponent } from './components/receiver-teams/receiver-teams.component';
import { ReportDownloadComponent } from './components/report/report-download/report-download.component';
import { SystemUsersComponent } from './components/system/system-users/system-users.component';
import { LoginFederationComponent } from './components/login/federation/federation.component';
import { OnOnMonitorComponent } from './components/onon/onon-monitor.component';
import { OnOnProfileManagementComponent } from './components/onon/onon-profile-management/onon-profile-management.component';
import { OnOnMonitorZoneComponent } from './components/onon/onon-monitor-zone/onon-monitor-zone.component';
import { MobileWhitelistComponent } from './components/mobile-whitelist/mobile-whitelist.component';
import { UnlocComponent } from './components/unloc/unloc.component';
import { CameraSupervisionComponent } from './components/camera-supervision/camera-supervision.component';
import { CreateNewVisitComponent } from './components/camera-supervision/create-new-visit/create-new-visit.component';
import { ResidentsComponent } from './components/residents/residents.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ReportSummaryComponent } from './components/report/report-summary/report-summary.component';
import { DeviceReportComponent } from './components/report/device-report/device-report.component';
import { EMAACStatisticsComponent } from './components/report/emaac-statistics/emaac-statistics.component';
import { AlarmReasonsComponent } from './components/alarm-reasons/alarm-reasons.component';
import { CompanyGroupManagementComponent } from './components/company-management/company-group-management/company-group-management.component';
import { CameraConfigurationComponent } from './components/camera-configuration/camera-configuration.component';
import { CompanygroupDevicesComponent } from './components/landing-page/devices/devices.component';
import { NewStaffComponent } from './components/landing-page/new-staff/new-staff.component';
import { ResidentsLandingPageComponent } from './components/landing-page/residents/residents-on-landing-page.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';

const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
  { path: "login/federation/:code", component: LoginFederationComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "dashboard/admin", component: DashboardAdminComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: "system/firmware", component: SystemFirmwareComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: "system/users", component: SystemUsersComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: "reports", component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/summary', component: ReportSummaryComponent, canActivate: [AuthGuard] },
  { path: 'reports/devices', component: DeviceReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/emaac-statistics', component: EMAACStatisticsComponent, canActivate: [AuthGuard] },
  { path: "reports/download", component: ReportDownloadComponent, canActivate: [AuthGuard] },
  { path: 'reports/statistics/old', component: GraphsComponent, canActivate: [AuthGuard] },
  { path: 'reports/statistics', component: StatisticsComponent, canActivate: [AuthGuard] },
  { path: 'reports/activity', component: ActivityComponent, canActivate: [AuthGuard] },
  { path: 'reports/activity/:id', component: ActivityComponent, canActivate: [AuthGuard] },
  { path: 'device', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'staff', component: StaffComponent, canActivate: [AuthGuard] },
  { path: 'staff/:id', component: StaffComponent, canActivate: [AuthGuard] },
  { path: 'device/:id', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'device-database', component: DeviceDatabaseComponent, canActivate: [AuthGuard] },
  { path: 'anna-perenna', component: AnnaPerennaMonitorComponent, canActivate: [AuthGuard, FeatureGuard], data: { feature: 'AnnaPerenna' } },
  { path: 'anna-perenna/edit/:id', component: AnnaPerennaMonitorEditComponent, canActivate: [AuthGuard, FeatureGuard, RoleGuard], data: { feature: 'AnnaPerenna', role: 'AnnaPerennaMainUser' } },
  { path: 'onon', component: OnOnMonitorComponent, canActivate: [AuthGuard, FeatureGuard], data: { feature: 'OnOn' } },
  { path: 'onon/profiles', component: OnOnProfileManagementComponent, canActivate: [AuthGuard, FeatureGuard, RoleGuard], data: { feature: 'OnOn', role: 'AnnaPerennaMainUser' } },
  { path: 'onon/zones/:id', component: OnOnMonitorZoneComponent, canActivate: [AuthGuard, FeatureGuard, RoleGuard], data: { feature: 'OnOn', role: 'AnnaPerennaMainUser' } },
  { path: 'route', component: AlarmRouteComponent, canActivate: [AuthGuard] },
  { path: 'route/:id', component: AlarmRouteComponent, canActivate: [AuthGuard] },
  { path: 'teams', component: ReceiverTeamsComponent, canActivate: [AuthGuard] },
  { path: 'teams/:id', component: ReceiverTeamsComponent, canActivate: [AuthGuard] },
  { path: 'cr', component: ControlRoomComponent, canActivate: [RoleGuard], data: { role: 'ControlRoomUser' } },
  { path: 'ap/:id', component: AnnaPerennaAlertViewComponent },
  { path: 'mobile-whitelist', component: MobileWhitelistComponent, canActivate: [AuthGuard, FeatureGuard, RoleGuard], data: { feature: 'EmaPhoneWhitelist', role: 'MainUser' } },
  { path: 'unloc', component: UnlocComponent, canActivate: [AuthGuard] }, // @TODO: canActivate: [AuthGuard, FeatureGuard], data: { feature: 'Unloc'}
  { path: 'camera-supervision', component: CameraSupervisionComponent, canActivate: [AuthGuard, FeatureGuard], data: { feature: 'CameraSupervision' } },
  { path: 'camera-supervision/new-visit', component: CreateNewVisitComponent, canActivate: [AuthGuard, FeatureGuard], data: { feature: 'CameraSupervision' } },
  { path: 'camera-configuration', component: CameraConfigurationComponent, canActivate: [AuthGuard, FeatureGuard, RoleGuard], data: { feature: 'CameraSupervision', roleLevel: 101} },
  { path: 'camera-configuration/:id', component: CameraConfigurationComponent, canActivate: [AuthGuard, FeatureGuard, RoleGuard], data: { feature: 'CameraSupervision', roleLevel: 101 } },
  { path: 'resident', component: ResidentsComponent, canActivate: [AuthGuard] },
  {
    path: 'landing-page', component: LandingPageComponent, canActivate: [SSOAuthGuard], children: [
      { path: '', redirectTo: 'residents', pathMatch: 'full' },
      { path: 'residents', component: ResidentsLandingPageComponent, canActivate: [SSOAuthGuard] },
      { path: 'staff', component: NewStaffComponent, canActivate: [SSOAuthGuard, SSORoleGuard], data: { role: '95' } },
      { path: 'devices', component: CompanygroupDevicesComponent, canActivate: [SSOAuthGuard, SSORoleGuard], data: { role: '95' } }
    ]
  },
  { path: 'system/company-management', component: CompanyManagementComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: 'system/company-management/:id', component: CompanyManagementComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: 'system/company-groups', component: CompanyGroupManagementComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: 'system/company-groups/:id', component: CompanyGroupManagementComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: 'alarm-reasons', component: AlarmReasonsComponent, canActivate: [RoleGuard], data: { role: 'SystemAdmin' } },
  { path: 'activity-log', component: ActivityLogComponent, canActivate: [RoleGuard], data: { role: 'CustomerAdmin' } },
  { path: '', redirectTo: "dashboard", pathMatch: "full" },
  { path: '**', redirectTo: "dashboard" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
