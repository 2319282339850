import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlarmRouteModel, CallCenterDeliveryType, DeliveryType, Priority, Route } from '../../../../models/alarmroutes.model';
import { CallCenter } from '../../../../models/callcenter.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, NgClass, KeyValuePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'alarm-route-basic-information',
    templateUrl: './alarm-route-basic-information.component.html',
    styleUrls: ['./alarm-route-basic-information.component.css'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, NgIf, NgClass, MatSlideToggleModule, MatTooltipModule, KeyValuePipe, TranslateModule]
})
export class AlarmRouteBasicInformationComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder) {
  }

  informationForm: UntypedFormGroup;
  @Input('tempRoute') tempRoute: Route;
  @Input('callCenters') callCenters: CallCenter[];
  @Input('alarmRouteLocations') alarmRouteLocations: AlarmRouteModel[];
  @Output('onLocationChange') locationChanged = new EventEmitter();

  showAdvancedSettings: boolean = false;

  priorityList: Priority[] = [Priority.Normal, Priority.Medium, Priority.High, Priority.Critical];

  callCenterDeliveryTypeTranslationsMap: Map<CallCenterDeliveryType, string> = new Map([
    [CallCenterDeliveryType.UnableToDeliverAlarm, 'UNABLE_TO_DELIVER_ALARM'],
    [CallCenterDeliveryType.CallCenterFirst, 'CALL_CENTER_FIRST'],
    [CallCenterDeliveryType.CallCenterOnly, 'CALL_CENTER_ONLY'],
    [CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly, 'UNABLE_TO_DELIVER_EMA_CALL_CENTER_ONLY'],
    [CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm, 'UNABLE_TO_DELIVER_EMA_ALARM'],
  ]);


  deliveryMethodGroups: DeliveryMethodGroup[] = [{
    name: 'DELIVERY_METHODS_INCLUDED',
    deliveryTypes: [DeliveryType.CallSMS, DeliveryType.Email, DeliveryType.Log,]
  }, {
    name: 'SPECIAL_CHARGEABLE_DELIVERY_METHODS',
    deliveryTypes: [DeliveryType.CallGroupSMS, DeliveryType.Smsonly]
  }];


  emaFeatureEnabled(): boolean {
    let features: [string] = JSON.parse(localStorage.getItem('features'));
    if (features) {
      return features.includes('EveronMobileApp');
    }
    return false;
  }

  getCallCenterDeliveryType(callCenterFirst: boolean, keepAlarmOpenAfterCallCenter: boolean, emaBeforeCallCenter: boolean): CallCenterDeliveryType {

    if (!callCenterFirst && !keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.UnableToDeliverAlarm;
    }

    if (callCenterFirst && keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.CallCenterFirst;
    }

    if (callCenterFirst && !keepAlarmOpenAfterCallCenter && !emaBeforeCallCenter) {
      return CallCenterDeliveryType.CallCenterOnly;
    }

    if (callCenterFirst && !keepAlarmOpenAfterCallCenter && emaBeforeCallCenter) {
      return CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly;
    }

    if (callCenterFirst && keepAlarmOpenAfterCallCenter && emaBeforeCallCenter) {
      return CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm;
    }
  }

  setCallCenterDeliveryType(callCenterDeliveryTypeOption: CallCenterDeliveryType): void {
    if (callCenterDeliveryTypeOption === CallCenterDeliveryType.UnableToDeliverAlarm) {
      this.tempRoute.callCenterFirst = false;
      this.tempRoute.keepAlarmOpenAfterCallCenter = false;
      this.tempRoute.emaBeforeCallCenter = false;
      return;
    }

    if (callCenterDeliveryTypeOption === CallCenterDeliveryType.CallCenterFirst) {
      this.tempRoute.callCenterFirst = true;
      this.tempRoute.keepAlarmOpenAfterCallCenter = true;
      this.tempRoute.emaBeforeCallCenter = false;
      return;
    }

    if (callCenterDeliveryTypeOption === CallCenterDeliveryType.CallCenterOnly) {
      this.tempRoute.callCenterFirst = true;
      this.tempRoute.keepAlarmOpenAfterCallCenter = false;
      this.tempRoute.emaBeforeCallCenter = false;
      return;
    }

    if (callCenterDeliveryTypeOption === CallCenterDeliveryType.WhenUnableToDeliverEMACallCenterOnly) {
      this.tempRoute.callCenterFirst = true;
      this.tempRoute.keepAlarmOpenAfterCallCenter = false;
      this.tempRoute.emaBeforeCallCenter = true;
      return;
    }

    if (callCenterDeliveryTypeOption === CallCenterDeliveryType.WhenUnableToDeliverEMAAlarm) {
      this.tempRoute.callCenterFirst = true;
      this.tempRoute.keepAlarmOpenAfterCallCenter = true;
      this.tempRoute.emaBeforeCallCenter = true;
      return;
    }
  }

  buildForms(): void {
    this.informationForm = this.fb.group({
      alarmRoutingName: [this.tempRoute && this.tempRoute.name || '', Validators.required],
      additionalInformation: [this.tempRoute && this.tempRoute.comment || ''],
      locationId: [this.tempRoute && this.tempRoute.locationId || ''],
      callCenterId: [this.tempRoute && this.tempRoute.callCenterId || ''],
      callCenterDeliverytype: [this.tempRoute && this.getCallCenterDeliveryType(this.tempRoute.callCenterFirst,
        this.tempRoute.keepAlarmOpenAfterCallCenter, this.tempRoute.emaBeforeCallCenter) || CallCenterDeliveryType.UnableToDeliverAlarm],
      fallbackCallCenterId: [this.tempRoute && this.tempRoute.fallBackCallCenter || 'NONE'],
      deliveryMethod: [this.tempRoute && this.tempRoute.deliveryType || ''],
      fallbackDeliveryMethod: [this.tempRoute && this.tempRoute.fallBackType || ''],
      waitForSmsAcknowledgement: [this.tempRoute && this.tempRoute.waitForSmsAcknowledgement || false],
      alarmIndicationGroupEnabled: [this.tempRoute && this.tempRoute.alarmIndicationGroupEnabled || false],
      priority: [this.tempRoute && this.tempRoute.priority || ''],
      skipInactiveTeamsForAssistance: [this.tempRoute && this.tempRoute.skipInactiveTeamsForAssistance || false],
      callLoopFailType: this.tempRoute.callLoopFailType
    });

    if (this.tempRoute.defaultRoute) {
      this.informationForm.get('alarmRoutingName').disable();
      this.informationForm.get('locationId').disable();
    }

    if (this.alarmRouteLocations?.length === 1) {
      this.informationForm.get('locationId').disable();
    }
  }

  saveAlarmRouteInformationToTempRoute(information: any): void {
    //If default route or only one available location, assign current location value as location id.
    if (this.tempRoute.defaultRoute || this.alarmRouteLocations.length === 1) {
      this.tempRoute.locationId = information.locationId || this.tempRoute.locationId;
    } else {

      //If location changes, trigger locationChangedEmitter, which will create new tempRoute with new information
      if (this.tempRoute.locationId !== information.locationId) {
        this.tempRoute.locationId = information.locationId;
        this.tempRoute.locationName = this.alarmRouteLocations.find(location =>
          location.locationId === information.locationId).locationName;
        this.locationChangedEmitter();
      }
    }

    //If default route, assign current name value as route name.
    if (this.tempRoute.defaultRoute) {
      this.tempRoute.name = information.alarmRoutingName || this.tempRoute.name;
    } else {
      this.tempRoute.name = information.alarmRoutingName;
    }

    this.tempRoute.comment = information.additionalInformation;
    this.tempRoute.callCenterId = information.callCenterId;

    this.tempRoute.deliveryType = information.deliveryMethod;

    // If deliveryMethod is EMAIL or LOG then reset fallback settings
    if (information.deliveryMethod === 'EMAIL' || information.deliveryMethod === 'LOG') {
      this.tempRoute.fallBackCallCenter = 'NONE';
      this.tempRoute.fallBackType = DeliveryType.None;
    } else {
      this.tempRoute.fallBackCallCenter = information.fallbackCallCenterId;
      this.tempRoute.fallBackType = information.fallbackDeliveryMethod;
    }

    // If fallbackDeliveryMethod is EMAIL or LOG then reset fallbackCallCenter setting
    if (information.fallbackDeliveryMethod === 'EMAIL' || information.fallbackDeliveryMethod === 'LOG') {
      this.tempRoute.fallBackCallCenter = 'NONE';
    }


    this.tempRoute.waitForSmsAcknowledgement = information.waitForSmsAcknowledgement;
    this.tempRoute.alarmIndicationGroupEnabled = information.alarmIndicationGroupEnabled;
    this.tempRoute.priority = information.priority;
    this.tempRoute.skipInactiveTeamsForAssistance = information.skipInactiveTeamsForAssistance;
    this.tempRoute.callLoopFailType = information.callLoopFailType;

    this.setCallCenterDeliveryType(information.callCenterDeliverytype);
  }


  onFormChange(): void {
    this.informationForm.valueChanges.subscribe(information => {
      this.saveAlarmRouteInformationToTempRoute(information);
    });
  }


  locationChangedEmitter(): void {
    this.locationChanged.emit();
  }

  ngOnInit() {
    this.buildForms();
    this.onFormChange();
  }

}


export interface DeliveryMethodGroup {
  name: string;
  deliveryTypes: DeliveryType[];
}
