import { Pipe, PipeTransform } from "@angular/core";
import { CrAlert } from "../services/crsync.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import * as moment from "moment-timezone";
import { HelperService } from "app/services/helper.service";
import { AlarmService } from "app/services/alarm.service";
import { take } from "rxjs/operators";
import { Device } from "app/models/device.model";
import { IAlarmReasonTranslations } from "app/models/alarm-reasons.model";
import { DeviceService } from "../services/device.service";
import { TranslateService } from "@ngx-translate/core";
import { LOCK_CATEGORY_LANG_CODES, LOCK_TYPE_LANG_CODES, UnlocDoorTypes } from "app/models/unloc.model";
import { ApiService } from "app/services/api.service";

@Pipe({
  name: "moment",
  standalone: true,
  pure: false,
})
export class MomentPipe implements PipeTransform {
  constructor(private helperService: HelperService) { }

  transform(value: string, format: string): string {
    return this.helperService.formatDate(value, format);
  }
}

@Pipe({
  name: "filter",
  pure: false,
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(arr: any[], filterField: string, filterBy: string): any {
    if (arr) {
      return arr.filter((obj) => {
        return obj[filterField] === filterBy;
      });
    } else {
      return [];
    }
  }
}

@Pipe({
  name: "highlight",
  standalone: true,
})
export class HighlightSearch implements PipeTransform {
  transform(value: string, args: string): any {
    if (args && value) {
      // Replace non-breaking hyphens with regular hyphens before performing the match
      value = value.replace(/&#x2011;/g, '-');

      let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
      if (startIndex != -1) {
        let endLength = args.length;
        let matchingString = value.substr(startIndex, endLength);
        return value.replace(
          matchingString,
          "<span class='yellow'>" + matchingString + "</span>"
        );
      }
    }
    return value;
  }
}

@Pipe({
  name: "highlightLink",
  standalone: true,
})
export class HighlightLinkSearchPipe implements PipeTransform {
  transform(value: string, args: string): any {
    if (args && value) {
      // Replace non-breaking hyphens with regular hyphens before performing the match
      value = value.replace(/&#x2011;/g, '-');

      let startIndex = value.toLowerCase().indexOf(args.toLowerCase());
      if (startIndex != -1) {
        let endLength = args.length;
        let matchingString = value.substr(startIndex, endLength);
        return value.replace(
          matchingString,
          `<a href="#" class='yellow'>${matchingString}</a>`
        );
      }
    }
    return value;
  }
}

@Pipe({
  name: "telephonify",
  standalone: true,
})
export class TelephonifyPipe implements PipeTransform {
  transform(text: string, format: string): string {
    var textOut = "";
    var numOrig = "";
    var numStripped = "";
    var separator = "";
    var plus = "";
    function _terminateNumber() {
      if (numStripped.length >= 7) {
        // is long enough to be a telephone numer --> make tel link
        textOut +=
          '<a class="infoTelLink" href="tel:' +
          numStripped +
          '">' +
          numOrig +
          "</a>";
      } else {
        // short number (not telephone number) --> add verbatim
        textOut += numOrig;
      }
      numOrig = "";
      numStripped = "";
    }

    for (var i = 0; i < text.length; i++) {
      var c = text[i];
      if (c >= "0" && c <= "9") {
        // is numeric --> add to number strings
        numOrig += separator + plus + c;
        numStripped += plus + c;
        separator = "";
        plus = "";
      } else if (
        numOrig !== "" &&
        separator === "" &&
        (c === "-" || c === " ")
      ) {
        // is separator (only one allowed) --> cache it
        separator = c;
        plus = "";
      } else if (c === "+") {
        // is plus (potentially starting telephone number) --> cache it
        if (numOrig !== "") _terminateNumber();

        // handle potentially preceding special chars
        // (order does not matter because at least one is an empty string)
        textOut += separator + plus;

        plus = "+";
        separator = "";
      } else {
        // not a number nor separator --> add verbatim
        if (numOrig !== "") _terminateNumber();

        textOut += plus + separator + text[i];
        separator = "";
        plus = "";
      }
    }

    // handle trailing number or separator
    if (numOrig !== "") _terminateNumber();
    textOut += separator;

    return textOut;
  }
}

@Pipe({
  name: "newLineToBreak",
  standalone: true,
})
export class NewLineToBreakPipe implements PipeTransform {
  transform(text: string): string {
    text = String(text).trim();
    return text.length > 0 ? text.replace(/\n/g, "<br />") : "";
  }
}

@Pipe({
  name: "safe",
  pure: true,
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }
  transform(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: "calculateAlertTime",
  pure: false,
  standalone: true,
})
export class CalculateAlertTime implements PipeTransform {
  transform(time: CrAlert["time"]) {
    let value;
    let momentDate = moment(time);
    if (momentDate.isValid()) {
      value = momentDate.fromNow();
    } else {
      value = time;
    }
    return value;
  }
}

@Pipe({
  name: 'safeHtml',
  pure: false,
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: "pickUpAlertIcon",
  pure: true,
  standalone: true,
})
export class PickUpAlertIcon implements PipeTransform {
  transform(alert: CrAlert): string {
    let picture_name;
    switch (alert.deviceType) {
      case "33-255":
        picture_name = "ema_assistance.png";
      case "34-1":
      case "34-2":
        picture_name = "ema_camera.png";
      case "10-5":
      case "10-9":
        picture_name = "ema_door.png";
      case "06-2":
        picture_name = "ema_emergency.png";
      case "89-1":
      case "10-2":
      case "88-1":
        picture_name = "ema_fire.png";
      case "10-3":
        picture_name = "ema_movement.png";
      case "32-1":
      case "32-3":
        picture_name = "ema_passage.png";
      default:
        picture_name = "ema_alert.png";
    }
    return "assets/img/control_room/" + picture_name;
  }
}

@Pipe({
  name: "pickUpActivationName",
  pure: true,
  standalone: true,
})
export class PickUpActivationName implements PipeTransform {
  constructor(private helper: HelperService) { }
  transform(alert: CrAlert): string {
    return this.helper.getActivationName(
      alert.alertGroup,
      alert.alertNode,
      alert.deviceType,
      "",
      0
    );
  }
}

@Pipe({
  name: "alreadyAdded",
  pure: true,
  standalone: true,
})
export class AlreadyAdded implements PipeTransform {
  constructor(private alarmService: AlarmService) { }

  transform(trigger: any, alertId: CrAlert["alertId"]): boolean {
    let presentInList;
    this.alarmService.alarmsWithEnabledBtns$
      .pipe(take(1))
      .subscribe((listOfAlarms) => {
        presentInList = listOfAlarms.includes(alertId);
      });
    return presentInList;
  }
}

@Pipe({ name: "deviceType", pure: true, standalone: true })
export class DeviceTypePipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(deviceType: string) {
    return this.helper.getDeviceType(deviceType);
  }
}

@Pipe({ name: "deviceTypeCode", pure: true, standalone: true })
export class DeviceTypeCodePipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(deviceType: string) {
    return this.helper.getDeviceTypeCode(deviceType);
  }
}

@Pipe({ name: "alertType", pure: true, standalone: true })
export class AlertTypePipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(
    deviceType: string,
    activationGroup: number,
    activationNode: number,
    connTimeLimit: number = 0
  ) {
    return this.helper.getActivationName(
      activationGroup,
      activationNode,
      deviceType,
      "",
      connTimeLimit
    );
  }
}

@Pipe({ name: "formatSeconds", pure: true, standalone: true })
export class SecondsToReadableFormatPipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(seconds: number) {
    return this.helper.convertSecondsToReadableFormat(seconds);
  }
}

/**
 * transform string from the format as '21.11.2022 15.43.48' into '21/11/2022 15:43'
 */
@Pipe({
  name: "dateSlashAndColon",
  pure: true,
  standalone: true,
})
export class StringToSlashAndColonDate implements PipeTransform {
  transform(arr: string): string {
    let splittedOnWHitespace = arr.split(" ");

    const replaceDotWith = (arg: string, replaceWith: string) => {
      while (arg.includes(".")) {
        arg = arg.replace(".", replaceWith);
      }
      return arg;
    };

    let date = replaceDotWith(splittedOnWHitespace[0], "/");
    let time = replaceDotWith(splittedOnWHitespace[1], ":");
    let withoutSeconds = time.slice(0, -3);

    let res = date + " " + withoutSeconds;
    return res;
  }
}

/**
 * Get camera name from the device list
 */
@Pipe({
  name: "cameraName",
  pure: true,
  standalone: true,
})
export class CameraNamePipe implements PipeTransform {
  transform(cameraId: string, deviceList: Device[]): string {
    const camera = deviceList.find((x) => x.id === cameraId);
    return camera ? camera.name : "-";
  }
}

/**
 * A universal pure pipe triggers a function call only when the value changes.
 * Using pure pipes instead of template function calls has a positive impact on performance.
 */
@Pipe({
  name: "function",
  pure: true,
  standalone: true
})
export class FunctionPipe implements PipeTransform {
  transform(value: any, func: Function, ...args: any[]) {
    if (args.length > 0) {
      return func(value, ...args);
    }
    return func(value);
  }
}

/**
 * Get the camera visit type icon
 */
@Pipe({
  name: "cameraVisitIcon",
  pure: true,
  standalone: true
})
export class CameraVisitIconPipe implements PipeTransform {
  transform(visitType: string): string {
    switch (visitType) {
      case "TYPE_SCHEDULED_VISIT":
        return "fa-calendar-alt text-warning";
      case "TYPE_ALERT_TRIGGERED_VISIT":
        return "fa-bells text-danger";
      default:
        return "fa-calendar-alt text-warning";
    }
  }
}

/**
 * Get alarm reason translation from the list
 */
@Pipe({
  name: "alarmReason",
  pure: true,
  standalone: true,
})
export class TranslateAlarmReasonPipe implements PipeTransform {
  transform(reasonKey: string, translations: IAlarmReasonTranslations): string {
    const obj = translations.alarmReason.find((x) => x.key === reasonKey);
    return obj ? obj.translation : "";
  }
}

/**
 * Get alarm categery translation from the list
 */
@Pipe({
  name: "alarmCategory",
  pure: true,
  standalone: true,
})
export class TranslateAlarmCategoryPipe implements PipeTransform {
  transform(
    categoryKey: string,
    translations: IAlarmReasonTranslations
  ): string {
    const obj = translations.alarmCategory.find((x) => x.key === categoryKey);
    return obj ? obj.translation : "";
  }
}

/**
 * Transform string into language code
 */
@Pipe({
  name: "langCode",
  pure: true,
  standalone: true,
})
export class TransformToLangCodePipe implements PipeTransform {
  transform(str: string): string {
    str = str.toLocaleUpperCase();
    str = str.replace(/ /g, "_");
    str = str.replace("/", "_");
    return str;
  }
}


@Pipe({
  name: 'signalQualityClass',
  pure: true,
  standalone: true,
})
export class SignalQualityClassPipe implements PipeTransform {
  constructor(private service: DeviceService) { }

  transform(value: any, args?: any): any {
    return this.service.getSignalQualityClass(value);
  }
}

// standalone pipe which takes a string and if there is hyphen in it,
// it wraps the hyphen with &nbsp; from the both sides
// for instance this Basstation (TP-202G) will be transformed into this Basestation (TP&nbsp;-&nbsp;202G)
@Pipe({
  name: 'wrapHyphen',
  pure: true,
  standalone: true,
})
export class WrapHyphenPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value.includes('-')) {
      return value.replace('-', '&#x2011;');
    }
    return value;
  }
}

/**
 * Get battery icon class
 */
@Pipe({
  name: 'batteryIcon',
  standalone: true,
})
export class BatteryIconPipe implements PipeTransform {
  constructor(private service: DeviceService) { }

  transform(value: number): any {
    return this.service.getBatteryClass(value);
  }
}

/**
 * Transform device binding time pipe
 */
@Pipe({
  name: 'bindingTime',
  standalone: true,
})
export class DeviceBindingTimePipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(bindingEnd: Date): string {
    return this.helper.getDaysBetweenDates(new Date(), new Date(bindingEnd)).toString();
  }
}

@Pipe({
  name: 'capitilize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return ''; // return empty string if value is undefined or null

    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}

@Pipe({
  name: 'userRole',
  standalone: true,
  pure: true
})
export class UserRolePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(roleLevel: number): string {
    switch (roleLevel) {
      case 101:
        return this.translateService.instant('STAFF_CUSTOMER_ADMIN');
      case 100:
        return this.translateService.instant('STAFF_MAIN_USER');
      case 50:
        return this.translateService.instant('STAFF_USER');
      case 40:
        return this.translateService.instant('STAFF_ONON_MAIN_USER');
      case 10:
        return this.translateService.instant('STAFF_EMA_USER');
      case 0:
        return this.translateService.instant('STAFF_READONLY');
      default:
        return this.translateService.instant('NONE');
    }
  }
}

@Pipe({
  name: 'lockRights',
  standalone: true,
  pure: true
})
export class LockRightsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(lockRights: number[], separator: string = ", "): string {
    const translations: string[] = [];

    for (const lockId of lockRights) {
      const type = LOCK_TYPE_LANG_CODES.find((x) => x.id === lockId);
      const category = LOCK_CATEGORY_LANG_CODES.find((x) => x.id === lockId);

      if (type) {
        const lockTypeTrans = this.translateService.instant(type.code);

        if (category) {
          const lockCatTrans = category.code ? `(${this.translateService.instant(category.code)})` : "";
          translations.push(lockTypeTrans + " " + lockCatTrans);
        } else {
          translations.push(lockTypeTrans);
        }
      }
    }

    return translations.length > 0
      ? translations.join(separator)
      : this.translateService.instant('NONE');
  }
}

@Pipe({
  name: 'identityFormat',
  standalone: true,
  pure: true
})
export class IdentityFormatPipe implements PipeTransform {
  transform(name: string, id: string) {
    let result = name;
    if (id) result += ` (${id})`;
    return result?.trim();
  }
}

@Pipe({
  name: 'propsFalsy',
  standalone: true,
  pure: true
})
export class PropsFalsyPipe implements PipeTransform {
  transform(value: any, props: string[] = []): boolean {
    // If the input value is null or undefined, return true.
    if (!value) {
      return true;
    }

    // Loop through each property name in the array.
    for (let key of props) {
      // If the input value has a truthy property for the current property name, return false.
      if (value[key]) {
        return false;
      }
    }
    // If none of the properties in the array are truthy on the input value, return true.
    return true;
  }
}

@Pipe({
  name: 'allPropsFalsy',
  standalone: true,
  pure: true
})
export class AllPropsFalsyPipe implements PipeTransform {

  transform(value: any, excludeProps: string[] = []): boolean {
    // Loop through each property in the input value.
    for (let key in value) {
      // If the property name is in the excludeProps array, skip it.
      if (excludeProps.includes(key)) {
        continue;
      }
      // If the property is truthy, return false.
      if (value[key]) {
        return false;
      }
    }
    // If all properties are falsy, return true.
    return true;
  }
}

@Pipe({
  name: 'join',
  standalone: true,
  pure: true
})
export class JoinPipe implements PipeTransform {

  transform(value: any[], separator: string = ', '): any {
    if (!Array.isArray(value)) {
      return "";
    }
    return value.join(separator);
  }

}

@Pipe({
  name: 'includes',
  standalone: true,
  pure: true
})
export class IncludesPipe implements PipeTransform {
  transform(array: any[], ...values: any[]): boolean {
    if (!array || !values) return false;
    return values.every(value => array.includes(value));
  }
}

@Pipe({
  name: 'some',
  standalone: true,
  pure: true
})
export class SomePipe implements PipeTransform {
  transform(array: any[], ...values: any[]): boolean {
    if (!array || !values) return false;
    return values.some(value => array.includes(value));
  }
}

@Pipe({
  name: 'substring',
  standalone: true,
  pure: true
})
export class SubstringPipe implements PipeTransform {
  transform(value: string, start: number, end?: number): string {
    if (!value) return value;

    if (end) {
      return value.substring(start, end);
    }
    return value.substring(start);
  }
}

@Pipe({
  name: 'translateMultiple',
  standalone: true,
  pure: true
})
export class TranslateMultiplePipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(values: string[]): string[] {
    const translations: string[] = [];

    values?.forEach(value => {
      translations.push(this.translate.instant(value));
    });

    return translations;
  }
}

@Pipe({
  name: 'deviceIconClass',
  standalone: true,
  pure: true
})
export class DeviceIconClassPipe implements PipeTransform {
  constructor(private api: ApiService) { }

  transform(deviceType: string): string {
    return this.api.getDeviceIconClass(deviceType);
  }
}

@Pipe({
  name: 'findDeviceType',
  standalone: true,
  pure: true
})
export class FindDeviceType implements PipeTransform {
  transform(deviceId: string, deviceMap: Map<string, Device>): string {
    return deviceMap?.get(deviceId)?.deviceType;
  }
}

@Pipe({
  name: 'findDeviceName',
  standalone: true,
  pure: true
})
export class FindDeviceName implements PipeTransform {
  transform(deviceId: string, deviceMap: Map<string, Device>): string {
    return deviceMap?.get(deviceId)?.name;
  }
}

@Pipe({
  name: 'deliveryClass',
  standalone: true,
  pure: true
})
export class DeliveryClassPipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(alert: any): string {
    return this.helper.getDeliveryClass(alert);
  }
}

@Pipe({
  name: 'isFeatureEnabled',
  standalone: true,
  pure: true
})
export class FeatureEnabledPipe implements PipeTransform {
  constructor(private helper: HelperService) { }

  transform(feature: string): boolean {
    return this.helper.isFeatureEnabled(feature);
  }
}
